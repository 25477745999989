<template>
    <base-section
      id="scan"
      space="10"
      class="grey lighten-4"
    >
      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
          >
            <base-business-info title="Cek Data" text="">
            </base-business-info>
            <template>
                <v-card
                  class="mx-auto"
                  color="surface-light"
                  max-width="600"
                >
                  <v-card-text>
                    <v-text-field
                      v-model="form.number"
                      :loading="loading"
                      append-icon="mdi-lock"
                      density="compact"
                      label="Masukkan Nomor Batch/Certificate/Claim"
                      hide-details
                      @keyup.enter="Decrypt"
                      @click:append="Decrypt"
                    ></v-text-field>
                  </v-card-text>
                </v-card>
              </template>
          </v-col>
        </v-row>
      </v-container>
       <!-- Dialog component -->
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
            <v-card-title class="primary white--text">Verified <v-icon color="green">mdi-check-decagram</v-icon> <v-spacer></v-spacer><v-btn color="white" text @click="dialog = false">Tutup</v-btn></v-card-title>
            <!-- <v-card-subtitle>Here is a table with some data:</v-card-subtitle> -->
    
            <!-- Data Table -->
             <template v-for="row in posts">
            <v-simple-table :key="row.website_batchno">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-right">
                        Batch No :
                      </th>
                      <th class="text-left">
                        {{ row.website_batchno }}
                      </th>
                    </tr>
                    <tr>
                        <th class="text-right">
                          Certificate No :
                        </th>
                        <th class="text-left">
                          {{ row.website_certificateno }}
                        </th>
                      </tr>
                    <tr>
                        <th class="text-right">
                          Total Data :
                        </th>
                        <th class="text-right">
                          {{ $functions.FormatNumber(row.website_totaldata,0) }}
                        </th>
                    </tr>
                    <tr>
                        <th class="text-right">
                          Total Plafond Kredit :
                        </th>
                        <th class="text-right">
                          {{ $functions.FormatNumber(row.website_totalguaranteevolume,0) }}
                        </th>
                      </tr>
                    <tr>
                        <th class="text-right">
                          Total Nilai Penjaminan :
                        </th>
                        <th class="text-right">
                          {{ $functions.FormatNumber(row.website_totalguaranteevalue,0) }}
                        </th>
                      </tr>
                    <tr>
                        <th class="text-right">
                          Total IJP :
                        </th>
                        <th class="text-right">
                          {{ $functions.FormatNumber(row.website_totalguaranteefee,0) }}
                        </th>
                      </tr>
                  </thead>
                </template>
              </v-simple-table>
            </template>
    
            <!-- Actions -->
            <v-card-actions>
                <v-spacer></v-spacer>
                PT. Orion Penjaminan Indonesia
            </v-card-actions>
            </v-card>
        </v-dialog>
      <v-snackbar 
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            v-model="snackbar.dialog"
            top
            >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar.dialog = false"
                >
                  Close
                </v-btn>
              </template>
        </v-snackbar>
    </base-section>
  </template>
  
  <script>
    export default {
      name: 'SectionScan',
        data: () => ({
            posts: [],
            loading: false,
            form: {
                number: ''
            },
            dialog: false,
            snackbar: {
                dialog: false,
                color: 'success',
                text: '',
                timeout: 3000,
            },
        }),
        created() {
            let access = Object.keys(this.$route.query)[0]
            if (access === undefined) {
                let data = this
                data.SnackBar(true,'error','Invalid URL',2000)
                setTimeout(() => {
                    data.$router.push({ name: "beranda"})
                }, 1500)
            }
        },
        methods: {
            Decrypt () {
                if (this.form.number === '' || this.form.number.length <= 7) {
                    this.form.number = ''
                    this.SnackBar(true,'error','Nomor  Tidak Valid',5000)
                } else {
                    this.loading  = true
                    let scan = Object.keys(this.$route.query)[0]
                    let formdata = {
                        encrypt_data: scan
                    }
                    let param      = this.$functions.ParamPOST(formdata)
                    this.$axios.post(this.$functions.UrlPOST('apiDecryptBarcode'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                    })
                    .then(response => {
                        let feedback = response.data
                        if (feedback.length > 0) {
                            if (feedback[0].feedback === 'Y') {
                            let split = feedback[0].decrypt_data.split("=")
                            let modul = split[0]
                            // let document = split[1]
                            let id = split[2]
                            this.CheckData(modul, id)
                            } else {
                                this.SnackBar(true,'error',feedback[0].feedback,5000)
                            }
                        }
                        this.loading  = false
                    })
                    .catch(e => {
                        this.SnackBar(true,'error',e,5000)
                        this.loading  = false
                    })
                }
            },
            CheckData(modul, id) {
                let validation = 'Y'
                if (modul === 'batchnocertificate') {
                    if (this.form.number !== id) {
                        validation = 'N'
                    }
                }
                if (validation === 'N') {
                    this.SnackBar(true,'error','Nomor yang dimasukkan tidak sesuai',5000)
                } else {
                    let formdata = {
                        website_modul: modul,
                        website_id: id,
                    }
                    let param      = this.$functions.ParamPOST(formdata)
                    this.$axios.post(this.$functions.UrlPOST('apiWebsiteData'),param,{
                    headers: {
                        'Content-Type': 'text/plain; charset=ISO-8859-1'
                    }
                    })
                    .then(response => {
                        let feedback = response.data
                        if (feedback.length > 0) {
                            if (feedback[0].feedback === 'Y') {
                                this.posts = feedback
                                this.dialog = true
                                this.SnackBar(true,'success','Nomor yang dimasukkan Valid dan Verified',3000)
                            } else {
                                this.SnackBar(true,'error',feedback[0].feedback,5000)
                            }
                        }
                        this.loading  = false
                    })
                    .catch(e => {
                        this.SnackBar(true,'error',e,5000)
                        this.loading  = false
                    })
                }
            },
            SnackBar (dialog, color, text, timeout) {
                this.snackbar.dialog  = dialog
                this.snackbar.color   = color
                this.snackbar.text    = text
                this.snackbar.timeout = timeout
            },
        }
    }
  </script>
  